<template>
  <div class="contact-card" :class="{ highlighted: contact.highlighted }">
    <div class="contact-header">
      <h2>{{ contact.name || "Sin Nombre" }}</h2>
      <button class="highlight-btn" @click="$emit('highlight', contact)" :class="{ highlighted: contact.highlighted }">
        <i class="fas fa-star"></i>
      </button>
    </div>

    <div class="contact-body">
      <div class="primary-info">
        <p><strong>Teléfono:</strong> <span>{{ contact.phone_number }}</span></p>
        <p><strong>Línea:</strong> <span>{{ contact.line_id }}</span></p>
      </div>
      <hr class="divider" />
      <div class="secondary-info">
        <p><strong>Mensajes Totales:</strong> <span class="second-info">{{ contact.total_messages }}</span></p>
        <p><strong>Respondidos:</strong> <span class="second-info">{{ contact.responded_messages }}</span></p>
        <p><strong>No Respondidos:</strong> <span class="second-info">{{ contact.unresponded_messages }}</span></p>
      </div>
    </div>

    <div class="action-buttons">
      <button class="delete-btnn" @click="$emit('delete', contact)">
        <i class="fas fa-trash"></i> Eliminar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.contact-card {
  background: linear-gradient(145deg, #ffffff, #f1f5f9);
  padding: 1.8rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 2px solid transparent;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
  overflow: hidden;
}

.contact-card.highlighted {
  border-color: #ff9800;
  background: linear-gradient(145deg, #fffbe6, #ffedcc);
}

.contact-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
}

.contact-header h2 {
  font-size: 1.6rem;
  font-weight: 700;
  color: #2d3748;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.highlight-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.6rem;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.2s;
}

.highlight-btn:hover {
  background-color: rgba(255, 152, 0, 0.2);
  transform: scale(1.1);
}

.highlight-btn .fa-star {
  font-size: 1.6rem;
  color: #b0bec5;
  transition: color 0.3s ease;
}

.highlight-btn.highlighted .fa-star {
  color: #ff9800;
}

.contact-body {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1.5rem 0;
  border-top: 2px solid #e2e8f0;
  border-bottom: 2px solid #e2e8f0;
}

.primary-info, .secondary-info {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.primary-info p, .secondary-info p {
  margin: 0;
  font-size: 1rem;
  color: #4a5568;
}

.primary-info span, .secondary-info span {
  font-size: 1.2rem;
  font-weight: bold;
  color: #1e40af;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-top: 1.2rem;
}

.delete-btnn {
  flex: 1;
  background: linear-gradient(145deg, #e53935, #c62828);
  color: #ffffff;
  border: none;
  padding: 0.6rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  transition: background 0.3s ease, transform 0.2s;
}

.delete-btnn:hover {
  background: linear-gradient(145deg, #c62828, #b71c1c);
  transform: translateY(-2px);
}

.delete-btnn:active {
  transform: translateY(1px);
}

@media (max-width: 768px) {
  .contact-card {
    padding: 1.2rem;
  }

  .contact-header h2 {
    font-size: 1.4rem;
  }

  .primary-info span, .secondary-info span {
    font-size: 1.1rem;
  }

  .edit-btn, .delete-btnn {
    font-size: 0.9rem;
    padding: 0.5rem 0.8rem;
  }
}

.divider {
  border: none;
  height: 1.5px;
  background-color: #7d7e7f;
  margin: 5px 0;
}
</style>
