<template>
  <div class="dashboard">

    <div class="export-container">
      <button class="btn-filters" @click="openFilterModal">
        <i class="fas fa-filter"></i>
      </button>

      <button class="btn-export" @click="exportData">
      <i class="fas fa-download"></i>
        <span class="success-msg" v-if="exportSuccess">¡Guardado!</span>
      </button>
    </div>

    <div v-if="isFilterModalOpen" class="filter-modal">
      <div class="filter-modal-content">
        <h3>Filtros</h3>
        <div class="filter-fields">
          <label for="startDate">Fecha de inicio:</label>
          <input type="date" v-model="filters.startDate" id="startDate" />
          <label for="endDate">Fecha de fin:</label>
          <input type="date" v-model="filters.endDate" id="endDate" />
          <label for="startTime">Hora de inicio:</label>
          <input type="time" v-model="filters.startTime" id="startTime" />
          <label for="endTime">Hora de fin:</label>
          <input type="time" v-model="filters.endTime" id="endTime" />
        </div>
        <div class="modal-buttons">
          <button @click="applyFilters">Aplicar Filtros</button>
          <button @click="resetFilters">Restablecer</button>
          <button @click="closeFilterModal">Cerrar</button>
        </div>
      </div>
    </div>

    <div v-if="isFiltersApplied" class="mini-modal">
      <p>Filtros aplicados:</p>
      <ul>
        <li v-if="filters.startDate && filters.endDate">
          Fecha: {{ filters.startDate }} - {{ filters.endDate }}
        </li>
        <li v-if="filters.startTime && filters.endTime">
          Hora: {{ filters.startTime }} - {{ filters.endTime }}
        </li>
      </ul>
      <button @click="clearAppliedFilters">Limpiar</button>
    </div>

    <h2 class="section-title">Métricas Generales</h2>
    <section class="widgets row mb-4">
      <div v-for="(widget, index) in generalWidgets" :key="index" class="col-md-3 mb-3">
        <div class="widget p-3 rounded shadow">
          <h5 class="widget-title">{{ widget.title }}</h5>
          <p class="widget-value">{{ widget.value }}</p>
        </div>
      </div>
    </section>

    <section class="charts">
      <div class="chart-container mb-4 small-chart small-pie-chart">
        <canvas id="generalStatusChart"></canvas>
      </div>
      <div class="chart-container mb-4 small-chart">
        <canvas id="generalHourlyChart"></canvas>
      </div>
      <div class="chart-container mb-4 small-chartt">
        <select v-model="selectedTimeFrame" class="chart-select" @change="renderGeneralTimeFrameChart">
          <option value="daily">Diario</option>
          <option value="weekly">Semanal</option>
          <option value="monthly">Mensual</option>
        </select>
        <canvas id="generalTimeFrameChart"></canvas>
      </div>
    </section>

    <h2 class="section-title">Métricas por Línea</h2>
    <section class="line-metrics">
      <div v-for="line in lineMetrics" :key="line.line_id" class="line-section">
        <div class="line-header">
          <div class="line-details">
            <h3 class="line-title">Línea {{ line.line_id }}</h3>
            <p class="line-phone">Teléfono: <span>{{ getLineInfo(line.line_id).phone }}</span></p>
          </div>
        </div>
        <div class="widgets row mb-4">
          <div class="col-md-3 mb-3">
            <div class="widget p-3 rounded shadow">
              <h5 class="widget-title">Total de Mensajes</h5>
              <p class="widget-value">{{ line.total_messages }}</p>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="widget p-3 rounded shadow">
              <h5 class="widget-title">Conversaciones Reales</h5>
              <p class="widget-value">{{ line.real_conversations }}</p>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="widget p-3 rounded shadow">
              <h5 class="widget-title">Tasa de Respuesta</h5>
              <p class="widget-value">{{ line.response_rate }}%</p>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <div class="widget p-3 rounded shadow">
              <h5 class="widget-title">Tiempo Promedio de Respuesta</h5>
              <p class="widget-value">{{ line.average_response_time === '-1.0000' ? 'N/A' : line.average_response_time }} min</p>
            </div>
          </div>
        </div>

        <section class="charts">
          <div class="chart-container mb-4 small-chart small-pie-chart">
            <canvas :id="'statusChart-' + line.line_id"></canvas>
          </div>
          <div class="chart-container mb-4 small-chart">
            <canvas :id="'hourlyChart-' + line.line_id"></canvas>
          </div>
          <div class="chart-container mb-4 small-chartt">
            <select v-model="line.selectedTimeFrame" class="chart-select" @change="renderLineTimeFrameChart(line)">
              <option value="daily">Diario</option>
              <option value="weekly">Semanal</option>
              <option value="monthly">Mensual</option>
            </select>
            <canvas :id="'timeFrameChart-' + line.line_id"></canvas>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { Chart } from "chart.js";
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      generalMetrics: {},
      lineMetrics: [],
      generalWidgets: [],
      selectedTimeFrame: "daily",
      lines: [],
      exportSuccess: false,
      isFilterModalOpen: false,
      isFiltersApplied: false,
      filters: {
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
      },
      generalStatusChart: null,
      generalHourlyChart: null,
      generalTimeFrameChart: null,
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${year}-${month}-${day}`;
    },
    formatTime(time) {
      if (!time) return null;

      const [timePart, modifier] = time.split(" ");
      let [hour, minute] = timePart.split(":").map(Number);

      if (modifier === "PM" && hour < 12) {
        hour += 12;
      } else if (modifier === "AM" && hour === 12) {
        hour = 0;
      }
      return `${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;
    },
    openFilterModal() {
      this.isFilterModalOpen = true;
    },
    closeFilterModal() {
      this.isFilterModalOpen = false;
    },
    async applyFilters() {
      try {
        this.isFilterModalOpen = false;
        this.isFiltersApplied = true;

        const { startDate, endDate, startTime, endTime } = this.filters;
        const queryParams = new URLSearchParams();

        if (startDate) queryParams.append("start_date", this.formatDate(startDate));
        if (endDate) queryParams.append("end_date", this.formatDate(endDate));
        if (startTime) queryParams.append("start_time", startTime);
        if (endTime) queryParams.append("end_time", endTime);

        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}?${process.env.VUE_APP_METRICS_GENERAL_ROUTE}&${queryParams.toString()}`
        );
        this.generalMetrics = response.data;
        this.updateGeneralMetrics();
        this.renderGeneralCharts();

        const updatedLineMetrics = [];
        for (const line of this.lines) {
          const lineResponse = await axios.get(
            `${process.env.VUE_APP_API_BASE_URL}?${process.env.VUE_APP_METRICS_X_LINE_ROUTE}${line.id}&${queryParams.toString()}`
          );
          updatedLineMetrics.push({ ...lineResponse.data, selectedTimeFrame: "daily" });
        }
        this.lineMetrics = updatedLineMetrics;

        this.$nextTick(() => this.renderLineCharts());
      } catch (error) {
        console.error("Error al aplicar los filtros:", error);
      }
    },
    async resetFilters() {
      this.filters = { startDate: "", endDate: "", startTime: "", endTime: "" };
      this.isFiltersApplied = false;

      await this.fetchGeneralMetrics();
      await this.fetchLineMetrics();
    },
    clearAppliedFilters() {
      this.resetFilters();
    },
    async fetchLines() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}?${process.env.VUE_APP_CHAT_LINES_ROUTE}`
        );
        this.lines = response.data;
      } catch (error) {
        console.error("Error al obtener las líneas:", error);
      }
    },
    getLineInfo(lineId) {
      const line = this.lines.find((line) => line.id === parseInt(lineId));
      return line
        ? { name: line.line_name, phone: line.phone_number }
        : { name: "Desconocida", phone: "Desconocido" };
    },
    async fetchGeneralMetrics() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}?${process.env.VUE_APP_METRICS_GENERAL_ROUTE}`
        );
        this.generalMetrics = response.data;

        this.updateGeneralMetrics();
        this.renderGeneralCharts();
      } catch (error) {
        console.error("Error al obtener las métricas generales:", error);
      }
    },
    async fetchLineMetrics() {
      try {
        const updatedLineMetrics = [];
        for (const line of this.lines) {
          const lineResponse = await axios.get(
            `${process.env.VUE_APP_API_BASE_URL}?${process.env.VUE_APP_METRICS_X_LINE_ROUTE}${line.id}`
          );
          updatedLineMetrics.push({ ...lineResponse.data, selectedTimeFrame: "daily" });
        }
        this.lineMetrics = updatedLineMetrics;
        this.$nextTick(() => this.renderLineCharts());
      } catch (error) {
        console.error("Error al obtener métricas por línea:", error);
      }
    },
    updateGeneralMetrics() {
      this.generalWidgets = [
        { title: "Total de Mensajes", value: this.generalMetrics.total_messages || 0 },
        { title: "Tasa de Respuesta", value: `${this.generalMetrics.response_rate || 0}%` },
        { title: "Tiempo Promedio de Respuesta", value: `${this.generalMetrics.average_response_time || "N/A"} min` },
        { title: "Conversaciones Reales", value: this.generalMetrics.real_conversations?.length || 0 },
        { title: "Clientes Activos", value: this.generalMetrics.active_vs_inactive_clients.active_clients || 0 },
        { title: "Clientes Inactivos", value: this.generalMetrics.active_vs_inactive_clients.inactive_clients || 0 },
        { title: "Tiempos Pico", value: this.generalMetrics.peak_times.map((pt) => pt.hour).join(", ") || "N/A" },
        { title: "Porcentaje de Respuestas Tardías", value: `${this.generalMetrics.late_response_rate || 0}%` },
        { title: "Mensajes Pendientes", value: this.generalMetrics.pending_messages || 0 },
      ];
    },
    renderGeneralCharts() {
      const statusCtx = document.getElementById("generalStatusChart").getContext("2d");
      if (this.generalStatusChart) this.generalStatusChart.destroy();

      this.generalStatusChart = new Chart(statusCtx, {
        type: "pie",
        data: {
          labels: ["Respondidos", "No Respondidos"],
          datasets: [
            {
              data: [
                this.generalMetrics.messages_by_status.find((s) => s.status === "respondido")?.total || 0,
                this.generalMetrics.messages_by_status.find((s) => s.status === "no respondido")?.total || 0,
              ],
              backgroundColor: ["#4CAF50", "#FF5252"],
            },
          ],
        },
      });

      const hourlyCtx = document.getElementById("generalHourlyChart").getContext("2d");
      if (this.generalHourlyChart) this.generalHourlyChart.destroy();

      this.generalHourlyChart = new Chart(hourlyCtx, {
        type: "bar",
        data: {
          labels: this.generalMetrics.messages_by_hour.map((item) => item.hour),
          datasets: [
            {
              label: "Mensajes por Hora",
              data: this.generalMetrics.messages_by_hour.map((item) => item.total),
              backgroundColor: "#1E293B",
            },
          ],
        },
      });

      this.renderGeneralTimeFrameChart();
    },
    renderGeneralTimeFrameChart() {
      const ctx = document.getElementById("generalTimeFrameChart").getContext("2d");

      if (this.generalTimeFrameChart) {
        this.generalTimeFrameChart.destroy();
        this.generalTimeFrameChart = null;
      }

      const data = this.generalMetrics.messages_by_time_frame[this.selectedTimeFrame];

      this.generalTimeFrameChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: data.map((item) => item.time_frame),
          datasets: [
            {
              label: `Mensajes (${this.selectedTimeFrame})`,
              data: data.map((item) => item.total),
              borderColor: "#60A5FA",
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    },
    renderLineCharts() {
      this.lineMetrics.forEach((line) => {
        const statusCanvas = document.getElementById(`statusChart-${line.line_id}`);
        const hourlyCanvas = document.getElementById(`hourlyChart-${line.line_id}`);
        const timeFrameCanvas = document.getElementById(`timeFrameChart-${line.line_id}`);

        if (!statusCanvas || !hourlyCanvas || !timeFrameCanvas) {
          console.warn(`Canvas no encontrado para línea ${line.line_id}`);
          return;
        }

        [statusCanvas, hourlyCanvas, timeFrameCanvas].forEach((canvas) => {
          const ctx = canvas.getContext("2d");
          if (Chart.getChart(ctx)) Chart.getChart(ctx).destroy();
        });

        const statusCtx = statusCanvas.getContext("2d");
        new Chart(statusCtx, {
          type: "pie",
          data: {
            labels: ["Respondidos", "No Respondidos"],
            datasets: [
              {
                data: [
                  line.messages_by_status?.respondido || 0,
                  line.messages_by_status?.no_respondido || 0,
                ],
                backgroundColor: ["#4CAF50", "#FF5252"],
              },
            ],
          },
        });

        const hourlyCtx = hourlyCanvas.getContext("2d");
        new Chart(hourlyCtx, {
          type: "bar",
          data: {
            labels: line.messages_by_hour.map((item) => item.hour),
            datasets: [
              {
                label: "Mensajes por Hora",
                data: line.messages_by_hour.map((item) => item.total),
                backgroundColor: "#1E293B",
              },
            ],
          },
        });

        this.renderLineTimeFrameChart(line);
      });
    },
    renderLineTimeFrameChart(line) {
      const ctx = document.getElementById(`timeFrameChart-${line.line_id}`).getContext("2d");
      const data = line.messages_by_time_frame[line.selectedTimeFrame];

      if (Chart.getChart(ctx)) Chart.getChart(ctx).destroy();

      new Chart(ctx, {
        type: "line",
        data: {
          labels: data.map((item) => item.time_frame),
          datasets: [
            {
              label: `Mensajes (${line.selectedTimeFrame})`,
              data: data.map((item) => item.total),
              borderColor: "#60A5FA",
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    },
    async exportData() {
      try {
        const generalData = [
          ["Métricas Generales"],
          ...this.generalWidgets.map((widget) => [widget.title, widget.value]),
          [],
          ["Gráficas Generales"],
          ["Horas", "Mensajes"],
          ...this.generalMetrics.messages_by_hour.map((item) => [item.hour, item.total]),
          [],
          ["Mensajes por Tiempo"],
          ["Periodo", "Total"],
          ...this.generalMetrics.messages_by_time_frame[this.selectedTimeFrame].map((item) => [
            item.time_frame,
            item.total,
          ]),
          [],
        ];

        const lineData = this.lineMetrics.flatMap((line) => [
          [`Línea ${line.line_id} (Tel: ${this.getLineInfo(line.line_id).phone})`],
          ["Total de Mensajes", line.total_messages],
          ["Conversaciones Reales", line.real_conversations],
          ["Tasa de Respuesta", `${line.response_rate}%`],
          [
            "Tiempo Promedio de Respuesta",
            line.average_response_time === "-1.0000" ? "N/A" : `${line.average_response_time} min`,
          ],
          [],
          ["Gráficas de la Línea"],
          ["Horas", "Mensajes"],
          ...line.messages_by_hour.map((item) => [item.hour, item.total]),
          [],
          ["Mensajes por Tiempo"],
          ["Periodo", "Total"],
          ...line.messages_by_time_frame[line.selectedTimeFrame].map((item) => [item.time_frame, item.total]),
          [],
        ]);

        const data = [...generalData, ...lineData];

        const worksheet = XLSX.utils.aoa_to_sheet(data); 
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Métricas"); 

        const xlsxBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([xlsxBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "dashboard_metrics.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.exportSuccess = true;
        setTimeout(() => {
          this.exportSuccess = false;
        }, 3000);
      } catch (error) {
        console.error("Error al exportar los datos:", error);
      }
    }
  },
  async mounted() {
    await this.fetchLines();
    await this.fetchGeneralMetrics();
    await this.fetchLineMetrics();
  },
};
</script>

<style>
.dashboard {
  padding: 1rem;
  background-color: var(--background-color);
}

.section-title {
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 0.5rem;
}

.widget {
  background-color: #fff;
  text-align: center;
  color: var(--text-color);
  border: 1px solid var(--secondary-color);
  height: 150px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

.widget:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.widget-title {
  font-size: 1rem;
  color: var(--primary-color);
}

.widget-value {
  font-size: 1.75rem;
  font-weight: bold;
  color: var(--accent-color);
}

.chart-container {
  background: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
}

.small-chart {
  max-width: 750px;
  max-height: 750px;
  width: 100%;
}

.small-chartt {
  max-width: 750px;
  max-height: 450px;
  width: 100%;
}

.small-pie-chart {
  max-width: 600px; 
  max-height: 500px; 
  width: 100%;
}

.chart-select {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  color: var(--text-color);
  background-color: var(--background-color);
  width: fit-content; 
  text-align: center;
  align-self: flex-start; 
  margin-left: 10px; 
}

.line-metrics {
  margin-top: 2rem;
}

.line-section {
  margin-bottom: 3rem;
  padding: 1rem;
  background-color: var(--sidebar-bg-color);
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.line-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.line-details {
  font-size: 1.1rem;
}

.line-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--primary-color);
}

.line-phone {
  font-size: 1rem;
  color: var(--text-color);
  margin-top: 0.25rem;
}

.line-phone span {
  font-weight: bold;
  color: var(--accent-color);
}

.export-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.btn-export {
  margin-left: 10px;
}

.btn-export, .btn-filters  {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.6rem 1.2rem;
  cursor: pointer;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-export i, .btn-filters i {
  margin-right: 0.5rem;
}

.btn-export:hover, .btn-filters:hover {
  background-color: var(--accent-color);
  transform: translateY(-2px);
}

.success-msg {
  position: absolute;
  top: 50px;
  right: -25px;
  font-size: 0.875rem;
  background-color: var(--success-color);
  background: linear-gradient(90deg, var(--secondary-color), var(--accent-color));
  color: white;
  padding: 0.4rem 0.6rem;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  animation: slide-down 0.5s ease, fade-out 3s forwards ease;
  z-index: 10;
}

.success-msg::before {
  content: '\2714'; 
  font-size: 1rem;
  margin-right: 0.5rem;
  color: white;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out {
  0%, 80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {
  .widget-value {
    font-size: 1.5rem;
  }

  .line-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .line-title {
    margin-bottom: 0.5rem;
  }
}

.filter-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.filter-modal-content {
  background: #f9f9f9;
  padding: 2.5rem;
  border-radius: 12px;
  width: 90%;
  max-width: 450px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  animation: slideUp 0.3s ease-out;
}

.filter-modal-content h3 {
  font-size: 1.5rem;
  color: var(--primary-color, #333);
  margin-bottom: 1rem;
  text-align: center;
}

.filter-fields label {
  font-weight: 600;
  color: var(--text-color, #555);
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.filter-fields input {
  width: 100%;
  padding: 0.7rem;
  margin-bottom: 1.2rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 0.95rem;
  background: #fff;
  transition: border 0.3s;
}

.filter-fields input:focus {
  border-color: var(--primary-color, #007bff);
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.modal-buttons button {
  flex: 1;
  padding: 0.8rem 1rem;
  font-size: 0.95rem;
  font-weight: 600;
  background: var(--primary-color, #007bff);
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.modal-buttons button:hover {
  background: var(--accent-color, #0056b3);
  transform: scale(1.03);
}

.modal-buttons button:nth-child(2) {
  background: var(--secondary-color, #6c757d);
}

.modal-buttons button:nth-child(2):hover {
  background: var(--secondary-hover, #5a6268);
}

.mini-modal {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 90%;
  max-width: 320px;
  animation: fadeIn 0.3s ease-out;
}

.mini-modal p {
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-color, #333);
  margin-bottom: 1rem;
}

.mini-modal ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mini-modal li {
  font-size: 0.95rem;
  color: var(--text-color, #555);
  margin-bottom: 0.5rem;
}

.mini-modal button {
  display: block;
  margin: 1rem auto 0;
  background: var(--primary-color, #007bff);
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0.7rem 1.2rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.mini-modal button:hover {
  background: var(--accent-color, #0056b3);
  transform: scale(1.05);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

</style>