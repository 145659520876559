<template>
  <div class="modal-overlay" role="dialog" aria-labelledby="modal-title" aria-hidden="false">
    <div class="modal-container">
      <div class="modal-header">
        <i class="modal-icon fas fa-exclamation-triangle"></i>
        <h3 class="modal-title">{{ title }}</h3>
      </div>
      <p class="modal-description" v-html="description"></p>
      <div class="modal-actions">
        <button class="modal-btn confirm-btn" @click="$emit('confirm')">Confirmar</button>
        <button class="modal-btn cancel-btn" @click="$emit('cancel')">Cancelar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmationModal",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 400px;
  text-align: center;
  animation: modal-scale-up 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}

.modal-icon {
  font-size: 2.5rem;
  color: #e11d48; 
  margin-bottom: 1rem;
}

.modal-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #111827; 
  margin: 0;
}

.modal-description {
  font-size: 1.1rem;
  color: #4b5563;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.modal-actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.modal-btn {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s, transform 0.2s;
}

.confirm-btn {
  background: #e11d48;
  color: #ffffff;
  width: 245px;
  height: 50px;
}

.confirm-btn:hover {
  background: #be123c; 
  transform: translateY(-2px);
}

.cancel-btn {
  background: #f3f4f6; 
  color: #1f2937;
  border: 1px solid #d1d5db; 
  height: 50px;
}

.cancel-btn:hover {
  background: #e5e7eb; 
  transform: translateY(-2px);
}

@keyframes modal-scale-up {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}
</style>
