<template>
  <div class="chat-simulation" :class="{ fullscreen: isFullscreen }">
    <div class="fullscreen-toggle">
      <button @click="toggleFullscreen">
        <i :class="isFullscreen ? 'fas fa-compress' : 'fas fa-expand'" class="icon"></i>
      </button>
    </div>

    <div class="contact-sidebar">
      <div class="search-bar">
        <input
          v-model="searchQuery"
          type="text"
          placeholder="Buscar contactos..."
        />
        <i class="fas fa-search icon-search"></i>
      </div>

      <div class="line-filter">
        <select v-model="selectedLine">
          <option value="">Todas las líneas</option>
          <option v-for="line in lines" :key="line.id" :value="line.id">
            {{ line.line_name }}
          </option>
        </select>
      </div>

      <div class="contact-list">
        <div
          v-for="contact in filteredContacts"
          :key="contact.contact.id"
          class="contact-item"
          :class="{ active: selectedContact && selectedContact.contact.id === contact.contact.id }" 
          @click="selectContact(contact)"
        >
          <div class="avatar">
            <i class="fas fa-user-circle"></i>
          </div>
          <div class="contact-info">
            <h3>{{ contact.contact.name }}</h3>
            <p>{{ contact.contact.phone_number }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="chat-window" v-if="selectedContact">
      <div class="chat-header">
        <div class="avatar">
          <i class="fas fa-user-circle"></i>
        </div>
        <div class="contact-info">
          <h2>{{ selectedContact.contact.name }}</h2>
          <p>{{ selectedContact.contact.phone_number }}</p>
        </div>
      </div>

      <div class="messages">
        <div
          v-for="message in selectedContactMessages"
          :key="message.id"
          :class="['message', message.sender]"
        >
          <p>{{ message.content }}</p>
          <span>{{ formatDate(message.created_at) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contacts: [],
      lines: [],
      searchQuery: "",
      selectedLine: "",
      selectedContact: null,
      isFullscreen: false, 
    };
  },
  computed: {
    filteredContacts() {
      return this.contacts.filter((contact) => {
        const matchesQuery =
          this.searchQuery === "" ||
          contact.contact.name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          contact.contact.phone_number.includes(this.searchQuery);

        const matchesLine =
          this.selectedLine === "" ||
          contact.contact.line_id === parseInt(this.selectedLine);

        return matchesQuery && matchesLine;
      });
    },
    selectedContactMessages() {
      return this.selectedContact
        ? this.contacts.find(
            (c) => c.contact.id === this.selectedContact.contact.id
          ).messages
        : [];
    },
  },
  methods: {
    async fetchLines() {
      const response = await fetch(
        `${process.env.VUE_APP_API_BASE_URL}?${process.env.VUE_APP_CHAT_LINES_ROUTE}`
      );
      this.lines = await response.json();
    },
    async fetchContacts() {
      const response = await fetch(
        `${process.env.VUE_APP_API_BASE_URL}?${process.env.VUE_APP_CHAT_CONTACTS_ROUTE}`
      );
      this.contacts = await response.json();
      this.restoreSelectedContact();
    },
    selectContact(contact) {
      this.selectedContact = contact;
      localStorage.setItem("selectedChat", JSON.stringify(contact.contact.id));
    },
    restoreSelectedContact() {
      const storedContactId = localStorage.getItem("selectedChat");
      if (storedContactId) {
        this.selectedContact = this.contacts.find(
          (contact) => contact.contact.id === parseInt(storedContactId) 
        );
      }
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen; 
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
  },
  async created() {
    await this.fetchLines();
    await this.fetchContacts();
  },
};
</script>

<style scoped>

:root {
  --background-color: #ece5dd; 
  --sidebar-bg-color: #ffffff; 
  --text-color: #000; 
}

.chat-simulation {
  display: flex;
  height: calc(97.5vh - 80px);
  width: 100%;
  position: relative;
  background-color: var(--background-color);
}

.chat-simulation.fullscreen {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: var(--background-color);
  transition: all 0.3s ease-in-out;
}

.fullscreen-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2000;
}

.fullscreen-toggle button {
  background-color: #ffffff;
  border: 2px solid  #075e54;
  color: #075e54;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.fullscreen-toggle button:hover {
  background-color: #075e54;
  color: #ffffff;
  transform: scale(1.1);
}

.fullscreen-toggle button i {
  pointer-events: none;
}

.contact-sidebar {
  width: 300px;
  background-color: var(--sidebar-bg-color);
  color: var(--text-color);
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
}

.search-bar {
  position: relative;
  padding: 0.75rem;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
}

.search-bar input {
  width: 100%;
  border: 1.5px solid #ccc;
  outline: none;
  padding: 0.5rem 2.5rem;
  border-radius: 20px;
  font-size: 1rem;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.search-bar input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 8px rgba(0, 255, 42, 0.568);
}

.search-bar .icon-search {
  position: absolute;
  top: 50%;
  left: 23px; 
  transform: translateY(-50%);
  color: #aaa;
  font-size: 1.2rem;
  pointer-events: none;
}

.line-filter {
  padding: 0.75rem;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.line-filter select {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1.5px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.line-filter select:focus {
  border-color: #075e54;
  box-shadow: 0 0 8px rgba(0, 255, 42, 0.568);
}

.contact-list {
  flex: 1;
  overflow-y: auto;
  padding: 0.5rem;
  background-color: #ffffff;
}

.contact-list, .messages {
  overflow-y: auto;
  scrollbar-width: thin; 
  scrollbar-color:  #075e54 #e0e0e0; 
}

.contact-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
  background-color: #fff;
  margin-bottom: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.contact-item:hover {
  background-color: var(--background-color);
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.contact-item .avatar {
  margin-right: 1rem;
  font-size: 2.5rem;
  color: #075e54;
}

.contact-item .contact-info h3 {
  font-size: 1.1rem;
  margin: 0;
  font-weight: bold;
  color: #333;
}

.contact-item .contact-info p {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

.chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  border-left: 1px solid #ddd;
}

.chat-header {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #075e54;
  color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.chat-header .avatar {
  margin-right: 1rem;
  font-size: 2.5rem;
  color: #fff;
}

.chat-header .contact-info h2 {
  font-size: 1.5rem;
  margin: 0;
  font-weight: bold;
}

.chat-header .contact-info p {
  margin: 0;
  font-size: 1rem;
  color: #e0e0e0;
}

.messages {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
  background-color: #e6ddc4; 
  scrollbar-width: thin;
  scrollbar-color: #075e54;
}

.contact-list::-webkit-scrollbar, .messages::-webkit-scrollbar {
  width: 6px;
}

.contact-list::-webkit-scrollbar-thumb, .messages::-webkit-scrollbar-thumb {
  background-color:  #075e54;
  border-radius: 10px;
}

.contact-list::-webkit-scrollbar-track, .messages::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.message {
  margin-bottom: 1rem;
  max-width: 60%;
  padding: 0.75rem 1rem;
  border-radius: 20px;
  font-size: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
  word-wrap: break-word;
}

.message.client {
  background-color: #dcf8c6; 
  align-self: flex-end;
}

.message.employee {
  background-color: #fff; 
  align-self: flex-start;
}

.message span {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.8rem;
  color: #888;
  text-align: right;
}

.contact-item.active {
  background-color: #075e54;
  color: #ffffff;
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.contact-item.active .avatar {
  color: #ffffff;
}

.contact-item.active:hover {
  background-color: #075e54; 
}


</style>
