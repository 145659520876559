<template>
  <div class="lines-management-container">
    <table class="lines-table">
      <thead>
        <tr>
          <th>Línea</th>
          <th>Número de Teléfono</th>
          <th>Estado</th>
          <th>Contactos</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="line in lines" :key="line.id">
          <td>{{ line.line_name }}</td>
          <td>{{ line.phone_number }}</td>
          <td>
            <span :class="line.status === 'active' ? 'status-active-text' : 'status-inactive-text'">
              {{ line.status }}
            </span>
          </td>
          <td>
            {{ line.total_contacts }}
            <button class="contacts-button" @click="viewContacts(line)">
              <i class="fas fa-users contacts-icon"></i>
            </button>
          </td>
          <td>
            <button class="delete-button" @click="confirmDelete(line)">
              <i class="fas fa-trash delete-icon"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="showContactsModal" class="modal-overlay">
      <div class="modal-container">
        <button class="close-modal-button" @click="showContactsModal = false">
          <i class="fas fa-times close-icon"></i>
        </button>
        <div class="modal-header">
          <h3 class="modal-title">Contactos de {{ selectedLine?.line_name }}</h3>
        </div>
        <ul class="contacts-list">
          <li v-for="contact in contacts" :key="contact.id" class="contact-item">
            {{ contact.name }} ({{ contact.phone_number }})
          </li>
        </ul>
      </div>
    </div>

    <div v-if="showDeleteModal" class="modal-overlay">
      <div class="modal-container">
        <div class="modal-header">
          <i class="fa-solid fa-triangle-exclamation modal-icon"></i>
          <h3 class="modal-title">¿Estás seguro de eliminar esta línea?</h3>
        </div>
        <p class="modal-description">
          Si eliminas esta línea, se eliminará todo su contenido relacionado.
        </p>
        <p class="modal-selected-line">{{ selectedLine?.line_name }}</p>
        <div class="modal-actions">
          <button class="modal-btn confirm-btn" @click="deleteLine">Eliminar</button>
          <button class="modal-btn cancel-btn" @click="showDeleteModal = false">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lines: [],
      contacts: [],
      selectedLine: null,
      showContactsModal: false,
      showDeleteModal: false,
    };
  },
  methods: {
    async fetchLines() {
      const response = await fetch(
        `${process.env.VUE_APP_API_BASE_URL}?${process.env.VUE_APP_MANAGE_LINES_LIST_ROUTE}`
      );
      this.lines = await response.json();
    },
    async viewContacts(line) {
      const response = await fetch(
        `${process.env.VUE_APP_API_BASE_URL}?${process.env.VUE_APP_MANAGE_LINES_VIEW_CONTACTS_ROUTE}${line.id}`
      );
      this.contacts = await response.json();
      this.selectedLine = line;
      this.showContactsModal = true;
    },
    confirmDelete(line) {
      this.selectedLine = line;
      this.showDeleteModal = true;
    },
    async deleteLine() {
      const response = await fetch(
        `${process.env.VUE_APP_API_BASE_URL}?${process.env.VUE_APP_MANAGE_LINES_DELETE_ROUTE}${this.selectedLine.id}`,
        {
          method: "GET",
        }
      );

      if (response.ok) {
        console.log("Línea eliminada exitosamente");
        await this.fetchLines();
        this.showDeleteModal = false;
      } else {
        console.error("Error al eliminar la línea");
      }
    },
  },
  async created() {
    await this.fetchLines();
  },
};
</script>

<style>
.lines-management-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background: #fefefe;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lines-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 14px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lines-table thead {
  background-color: #555;
  color: #fff;
}

.lines-table th,
.lines-table td {
  padding: 12px 15px;
  text-align: left;
  vertical-align: middle;
}

.lines-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.lines-table tbody tr:hover {
  background-color: #f1f1f1;
}

.status-active-text {
  color: #28a745;
  font-weight: bold;
}

.status-inactive-text {
  color: #dc3545;
  font-weight: bold;
}

.contacts-button,
.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.contacts-button:hover .contacts-icon,
.delete-button:hover .delete-icon {
  transform: scale(1.2);
}

.contacts-icon {
  color: #007bff;
  font-size: 1.2rem;
}

.delete-icon {
  color: #dc3545;
  font-size: 1.2rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  position: relative;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 400px;
  text-align: center;
  animation: modal-scale-up 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}

.modal-icon {
  font-size: 2.5rem;
  color: #e11d48;
  margin-bottom: 1rem;
}

.modal-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #111827;
  margin: 0;
}

.modal-description {
  font-size: 1.1rem;
  color: #4b5563;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.modal-actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.modal-btn {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  width: 245px;
  height: 50px;
  transition: background-color 0.3s, transform 0.2s;
}

.confirm-btn {
  background: #e11d48;
  color: #ffffff;
}

.confirm-btn:hover {
  background: #be123c;
  transform: translateY(-2px);
}

.cancel-btn {
  background: #f3f4f6;
  color: #1f2937;
  border: 1px solid #d1d5db;
}

.cancel-btn:hover {
  background: #e5e7eb;
  transform: translateY(-2px);
}

@keyframes modal-scale-up {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

.close-modal-button {
  position: absolute;
   top: 10px; 
  right: 10px; 
  background: none;
  border: none;
  font-size: 1.4rem;
  cursor: pointer;
  color: #444;
  z-index: 10; 
}

.close-modal-button:hover .close-icon {
  color: #222;
}
</style>
