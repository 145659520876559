<template>
  <div id="app">
    <Header v-if="authState.isLoggedIn" />
    <Sidebar v-if="authState.isLoggedIn" />
    <main :class="{ 'content': authState.isLoggedIn }">
      <router-view />
    </main>
  </div>
</template>

<script>
import Header from './components/AppHeader.vue';
import Sidebar from './components/AppSidebar.vue';
import { authState } from './auth';

export default {
  components: {
    Header,
    Sidebar,
  },
  setup() {
    return { authState };
  },
};
</script>

<style>
.content {
  margin-top: 60px;
  margin-left: 255px;
  padding: 1rem;
  min-height: calc(100vh - 60px);
  overflow: auto;
  background-color: var(--background-color);
}
</style>
