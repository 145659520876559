<template>
  <header class="d-flex justify-content-between align-items-center p-3 header">
    <div class="d-flex align-items-center">
      <img src="../assets/images/logoSinFondo.png" alt="Logo" class="logo" />
      <h4 class="mb-0">DataScape</h4>
    </div>
    <div class="d-flex align-items-center">
      <span class="me-3 date-time">{{ formattedDateTime }}</span>
      <button @click="logout" class="btn btn-secondary">Cerrar Sesión</button>
    </div>
  </header>
</template>

<script>
import { logout } from '../auth';

export default {
  data() {
    return {
      currentDateTime: new Date(),
    };
  },
  computed: {
    formattedDateTime() {
      const now = this.currentDateTime;
      return `${now.toLocaleDateString('es-ES', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
      })}, ${now.toLocaleTimeString('es-ES', {
        hour: '2-digit',
        minute: '2-digit',
      })}`;
    },
  },
  methods: {
    logout() {
      logout(); 
      this.$router.push('/login'); 
    },
  },
  created() {
    setInterval(() => {
      this.currentDateTime = new Date();
    }, 1000);
  },
};
</script>

<style>
.header {
  background-color: var(--primary-color);
  color: var(--background-color);
  height: 60px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 1.2rem;
}

.logo {
  margin-right: 0px;
  width: 110px;
}

h4 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: -20px;
}

.date-time {
  font-weight: 500;
  font-size: 1rem;
  color: var(--secondary-color);
  letter-spacing: 0.5px;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.p-3 {
  padding: 1rem;
}

@media (max-width: 768px) {
  .header {
    height: 70px;
    padding: 0.8rem;
  }

  .date-time {
    font-size: 1rem;
  }
}
</style>
