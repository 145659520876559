<template>
  <div class="login-container">
    <div class="login-card">
      <h2 class="login-title">Iniciar Sesión</h2>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="username">Usuario</label>
          <input 
            type="text" 
            id="username" 
            v-model="username" 
            class="login-input"
            placeholder="Ingresa tu usuario"
            required 
          />
        </div>
        <div class="form-group">
          <label for="password">Contraseña</label>
          <div class="password-wrapper">
            <input 
              :type="showPassword ? 'text' : 'password'" 
              id="password" 
              v-model="password" 
              class="login-input"
              placeholder="Ingresa tu contraseña"
              required 
            />
            <span @click="togglePassword" class="password-toggle">
              <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
            </span>
          </div>
        </div>
        <p v-if="errorMessage" class="login-error">{{ errorMessage }}</p>
        <button type="submit" class="login-button">Iniciar Sesión</button>
      </form>
    </div>
    <div class="background-circles"></div>
  </div>
</template>

<script>
import { login } from '../auth';

export default {
  data() {
    return {
      username: '',
      password: '',
      showPassword: false,
      errorMessage: null,
    };
  },
  methods: {
    async login() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}?route=login&username=${this.username}&password=${this.password}`);
        const data = await response.json();

        if (response.ok) {
          login(data.token); 
          this.$router.push('/dashboard');
        } else {
          this.errorMessage = data.error;
        }
      } catch (error) {
        this.errorMessage = 'Error al iniciar sesión';
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(135deg, #dfe9f3, #ffffff);
  position: relative;
  overflow: hidden;
}

.background-circles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: radial-gradient(circle at top left, rgba(135, 206, 250, 0.2) 10%, transparent 40%),
              radial-gradient(circle at bottom right, rgba(144, 238, 144, 0.2) 10%, transparent 40%);
}

.login-card {
  z-index: 1;
  background: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  width: 350px;
  text-align: center;
}

.login-title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #333333;
}

.form-group {
  margin-bottom: 1.5rem;
  text-align: left;
}

.form-group label {
  font-size: 0.9rem;
  color: #666666;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.login-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 1rem;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
}

.login-input:focus {
  border-color: #60a5fa;
  outline: none;
  box-shadow: 0px 0px 4px rgba(96, 165, 250, 0.5);
}

.password-wrapper {
  position: relative;
}

.password-toggle {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999999;
  transition: color 0.3s;
}

.password-toggle:hover {
  color: #333333;
}

.login-button {
  background-color: #60a5fa;
  color: #ffffff;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  padding: 0.75rem;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.login-button:hover {
  background-color: #3b82f6;
}

.login-error {
  color: #ec1a03;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  text-align: left;
}

button, input, .password-toggle {
  transition: all 0.3s ease-in-out;
}
</style>
