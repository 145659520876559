<template>
  <div class="contact-list">
    <div class="filters">
      <input type="text" v-model="searchQuery" placeholder="Buscar por nombre o teléfono..." />
      <select v-model="selectedLine">
        <option value="">Todas las Líneas</option>
        <option v-for="line in uniqueLines" :key="line" :value="line">Línea {{ line }}</option>
      </select>
    </div>

    <div class="contact-grid">
      <ContactCard
        v-for="contact in filteredContacts"
        :key="contact.id"
        :contact="contact"
        @highlight="toggleHighlight"
        @delete="openDeleteModal"
      />
    </div>

    <ConfirmationModal
      v-if="showModal"
      title="Confirmar Eliminación"
      :description="'¿Estás seguro de que deseas eliminar este contacto?<br><br>Al eliminar un contacto también se eliminarán sus mensajes asociados.'"
      @confirm="deleteContact"
      @cancel="closeModal"
    />
  </div>
</template>

<script>
import axios from "axios";
import ContactCard from "../components/ContactCard.vue";
import ConfirmationModal from "../components/ConfirmationModal.vue";

export default {
  components: {
    ContactCard,
    ConfirmationModal,
  },
  data() {
    return {
      contacts: [],
      searchQuery: "",
      selectedLine: "",
      showModal: false,
      selectedContact: null,
    };
  },
  computed: {
    filteredContacts() {
      return this.contacts
        .filter(contact => {
          const matchesLine = !this.selectedLine || contact.line_id === parseInt(this.selectedLine);
          const matchesQuery =
            !this.searchQuery ||
            contact.name?.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            contact.phone_number?.includes(this.searchQuery);

          return matchesLine && matchesQuery;
        })
        .sort((a, b) => b.highlighted - a.highlighted || a.id - b.id);
    },
    uniqueLines() {
      return [...new Set(this.contacts.map(contact => contact.line_id))];
    },
  },
  methods: {
    async fetchContacts() {
      try {
        const response = await axios.get(
          this.buildApiUrl(
            process.env.VUE_APP_API_BASE_URL,
            process.env.VUE_APP_CONTACT_LIST_ROUTE
          )
        );
        const storedHighlights = JSON.parse(localStorage.getItem("highlightedContacts")) || [];
        this.contacts = response.data.map(contact => ({
          ...contact,
          highlighted: storedHighlights.includes(contact.id),
        }));
      } catch (error) {
        console.error("Error al cargar contactos:", error);
        alert("Hubo un problema al cargar los contactos. Por favor, inténtalo de nuevo.");
      }
    },
    toggleHighlight(contact) {
      const index = this.contacts.findIndex(c => c.id === contact.id);
      if (index !== -1) {
        this.contacts[index].highlighted = !this.contacts[index].highlighted;
        const highlightedContacts = this.contacts.filter(c => c.highlighted).map(c => c.id);
        localStorage.setItem("highlightedContacts", JSON.stringify(highlightedContacts));
      }
    },
    openDeleteModal(contact) {
      this.selectedContact = contact;
      this.showModal = true;
      document.body.style.overflow = "hidden";
    },
    closeModal() {
      this.showModal = false;
      this.selectedContact = null;
      document.body.style.overflow = "auto";
    },
    async deleteContact() {
      if (!this.selectedContact) return;

      try {
        await axios.get(
          this.buildApiUrl(
            process.env.VUE_APP_API_BASE_URL,
            process.env.VUE_APP_CONTACT_DELETE_ROUTE,
            this.selectedContact.id
          )
        );

        this.contacts = this.contacts.filter(contact => contact.id !== this.selectedContact.id);
        this.closeModal();
      } catch (error) {
        console.error("Error al eliminar contacto:", error);
        alert("Hubo un problema al intentar eliminar el contacto. Por favor, inténtalo de nuevo.");
      }
    },
    buildApiUrl(base, route, params = "") {
      return `${base}?${route}${params}`;
    },
  },
  async mounted() {
    await this.fetchContacts();
  },
};
</script>

<style>
.contact-list {
  padding: 1.5rem;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--sidebar-bg-color);
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  margin: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 3rem;
}

.filters input[type="text"],
.filters select {
  flex: 1;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  background-color: #ffffff;
  color: var(--text-color);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.filters input[type="text"]:focus,
.filters select:focus {
  border-color: var(--accent-color);
  box-shadow: 0 0 6px rgba(249, 115, 22, 0.4);
  outline: none;
}

.filters select {
  cursor: pointer;
}

.filters input[type="text"]::placeholder {
  color: var(--text-color);
  opacity: 0.7;
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
}

.contact-card {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  border: 2px solid transparent;
}

.contact-card.highlighted {
  border-color: var(--accent-color) !important;
}

.delete-btn {
  margin-top: 1rem;
  background: var(--primary-color);
  color: var(--background-color);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: center;
  transition: background 0.5s ease;
}

.delete-btn:hover {
  background: var(--accent-color);
}
</style>
