import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from '@/views/AdminDashboard.vue';
import Contacts from '@/views/ContactList.vue';
import Lines from '@/views/LineManagement.vue';
import Chat from '@/views/ChatSimulation.vue';
import Login from '@/views/LoginUser.vue';

const routes = [
  { path: '/login', component: Login },
  { path: '/dashboard', component: Dashboard, meta: { requiresAuth: true } },
  { path: '/contacts', component: Contacts, meta: { requiresAuth: true } },
  { path: '/lines', component: Lines, meta: { requiresAuth: true } },
  { path: '/chat', component: Chat, meta: { requiresAuth: true } },
  { path: '/', redirect: '/login' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.meta.requiresAuth && !token) {
    next('/login');
  } else if (to.path === '/login' && token) {
    next('/dashboard');
  } else {
    next();
  }
});

export default router;
