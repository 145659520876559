// src/main.js

import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.css'; 
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.min.js';
import './assets/styles.css'; 

import axios from 'axios';
import router from './router';
import { BootstrapVue3 } from 'bootstrap-vue-3';

// Chart.js configuración
import {
    Chart,
    ArcElement, 
    LineElement,
    BarElement,
    PointElement,
    PieController,
    BarController,
    LineController,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from 'chart.js';
  
// Registrar componentes de Chart.js
Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    PieController,
    BarController,
    LineController,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
);

// Configuración global de Axios
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

const app = createApp(App);

app.use(router);
app.use(BootstrapVue3);

app.mount('#app');
