<template>
  <aside class="sidebar">
    <ul class="list-unstyled">
      <li v-for="item in menuItems" :key="item.name">
        <router-link
          :to="item.link"
          class="menu-item d-flex align-items-center text-decoration-none"
          active-class="active"
        >
          <i :class="item.icon" class="menu-icon me-3"></i> 
          <span class="menu-text">{{ item.name }}</span>
        </router-link>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        { name: 'Dashboard', link: '/dashboard', icon: 'fas fa-chart-line' },
        { name: 'Contactos', link: '/contacts', icon: 'fas fa-address-book' },
        { name: 'Simulación de Chat', link: '/chat', icon: 'fas fa-comments' },
        { name: 'Líneas', link: '/lines', icon: 'fas fa-phone' },
      ],
    };
  },
};
</script>

<style>
.sidebar {
  background-color: var(--sidebar-bg-color);
  color: var(--text-color);
  width: 255px;
  position: fixed;
  top: 60px;
  left: 0;
  height: calc(100vh - 60px);
  overflow-y: auto;
  border-right: 1px solid var(--primary-color);
}

.menu-item {
  padding: 25px 26px;
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-size: 1rem;
  transition: background-color 0.3s, color 0.3s;
}

.menu-item:hover {
  background-color: var(--sidebar-hover-bg);
  color: var(--primary-color);
}

.menu-icon {
  font-size: 1.4rem;
}

.menu-text {
  font-size: 1.1rem;
}

.active {
  background-color: var(--sidebar-hover-bg);
  color: var(--primary-color);
}
</style>
